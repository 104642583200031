/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { useQueryParam, StringParam } from "use-query-params";
import { Button, Go, Grid, ProductCard } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { useWindowDimensions } from "~hooks";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 5rem 0 2rem;

  ${MEDIA_QUERIES?.desktop} {
    padding: 6rem 0 3rem;
  }
`;

const ContentHeader = styled.header`
  grid-column: span 10 / span 10;
  grid-column-start: 2;
  position: relative;
  margin-bottom: 0.5rem;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 12 / span 12;
    grid-column-start: 1;
    margin-bottom: 1.5rem;
  }
`;

const Heading = styled.h2`
  text-align: center;
`;

const CategoryText = styled.h2`
  text-align: center;
`;

const ButtonContainer = styled.div`
  grid-column: span 12 / span 12;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 0;

  ${MEDIA_QUERIES?.desktop} {
    padding: 3rem 0 3rem;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const ProductGrid = ({ data: { heading, button, withFilters, products } }) => {
  // ---------------------------------------------------------------------------
  // variables

  const [urlCategory] = useQueryParam(`category`, StringParam);

  const { isDesktop } = useWindowDimensions();

  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(urlCategory);
  const [tags, setTags] = useState([]);
  const [activeTag, setActiveTag] = useState(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setActiveCategory(urlCategory);
  }, [urlCategory]);

  useEffect(() => {
    if (!products?.[0]) {
      return;
    }

    //
    // categories

    const parsedCategories = [];

    products.forEach((product) => {
      if (
        !product?.category?.title ||
        parsedCategories?.includes(product.category.title)
      ) {
        return;
      }

      parsedCategories.push(product.category.title);
    });

    setCategories(parsedCategories);

    //
    // tags

    const parsedTags = [];

    products.forEach((product) => {
      if (!product?.sourceData?.tags?.[0]) {
        return;
      }

      product.sourceData.tags.forEach((tag) => {
        if (!parsedTags?.includes(tag) && !parsedCategories?.includes(tag)) {
          parsedTags.push(tag);
        }
      });
    });

    setTags(parsedTags);
  }, [products]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Wrapper>
      <Grid node="article">
        <ContentHeader>
          <Heading className="h2">{heading}</Heading>

          {withFilters && (
            <div
              css={css`
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 1rem;

                ${MEDIA_QUERIES?.desktop} {
                  margin-bottom: 1rem;
                }
              `}
            >
              {isDesktop ? (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 1.25rem;
                  `}
                >
                  <CategoryText className="b1">Categories:&nbsp;</CategoryText>

                  {categories?.[0] && (
                    <ul
                      css={css`
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      `}
                    >
                      {categories.map((category, categoryIndex) => {
                        const isActive = category === activeCategory;

                        return (
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                setActiveCategory(isActive ? null : category)
                              }
                              css={css`
                                ${MEDIA_QUERIES?.hoverable} {
                                  &:hover {
                                    span {
                                      font-style: italic;
                                    }
                                  }
                                }

                                span {
                                  font-style: ${isActive ? `italic` : `normal`};
                                }
                              `}
                            >
                              <p className="b1">
                                <span>{category}</span>

                                {categoryIndex < categories?.length - 1 && (
                                  <span>,&nbsp;</span>
                                )}
                              </p>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              ) : (
                <>
                  {categories?.[0] && (
                    <div
                      css={css`
                        position: relative;
                        text-align: center;
                      `}
                    >
                      <p
                        css={css`
                          display: inline-block;
                          margin-right: 0.25rem;
                        `}
                        className={isDesktop ? `b3` : `b1`}
                      >
                        Categories:
                      </p>

                      {categories.map((category, categoryIndex) => {
                        const isActive = category === activeCategory;

                        return (
                          <div
                            key={`xs-category-button-${category}`}
                            css={css`
                              display: inline-block;
                            `}
                          >
                            <button
                              type="button"
                              onClick={() =>
                                setActiveCategory(isActive ? null : category)
                              }
                            >
                              <p className={isDesktop ? `b3` : `b1`}>
                                <span
                                  css={css`
                                    font-style: ${isActive
                                      ? `italic`
                                      : `normal`};
                                  `}
                                >
                                  {category}
                                </span>

                                {categoryIndex < categories?.length - 1 && (
                                  <span>,&nbsp;</span>
                                )}
                              </p>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {tags?.[0] && activeCategory?.toLowerCase() === `self care tools` && (
            <div
              css={css`
                height: 2rem;
                margin-bottom: 1.5rem;

                ${MEDIA_QUERIES?.hoverable} {
                  margin-bottom: 3rem;
                }
              `}
            >
              <ul
                css={css`
                  height: 100%;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <li
                  className="b3"
                  css={css`
                    margin-right: 0.5rem;
                  `}
                >
                  Filter:
                </li>

                {tags.map((tag, tagIndex) => {
                  const isActive = tag === activeTag;

                  return (
                    <li>
                      <button
                        type="button"
                        onClick={() => setActiveTag(isActive ? null : tag)}
                        css={css`
                          ${MEDIA_QUERIES?.hoverable} {
                            &:hover {
                              span {
                                font-style: italic;
                              }
                            }
                          }

                          span {
                            font-style: ${isActive ? `italic` : `normal`};
                          }
                        `}
                      >
                        <p className="b3">
                          <span>{tag}</span>

                          {tagIndex < tags?.length - 1 && <span>,&nbsp;</span>}
                        </p>
                      </button>
                    </li>
                  );
                })}

                <li>
                  <button
                    type="button"
                    onClick={() => setActiveTag(null)}
                    css={css`
                      ${MEDIA_QUERIES?.hoverable} {
                        &:hover {
                          span {
                            font-style: italic;
                          }
                        }
                      }

                      span {
                        font-style: ${activeTag === null ? `italic` : `normal`};
                      }
                    `}
                  >
                    <p className="b3">
                      <span>, All</span>
                    </p>
                  </button>
                </li>
              </ul>
            </div>
          )}
        </ContentHeader>

        {products?.[0] &&
          products
            .slice(0, withFilters ? products.length : 3)
            .map((product) => {
              const key = product?.handle;

              const matchesCategory =
                !activeCategory || product?.category?.title === activeCategory;

              let matchesTag = activeTag === null;

              if (
                !matchesTag &&
                activeCategory?.toLowerCase() === `self care tools`
              ) {
                matchesTag = product?.sourceData?.tags?.includes(activeTag);
              }

              if (product?.archived || !matchesCategory || !matchesTag) {
                return <React.Fragment key={key} />;
              }

              return (
                <div
                  key={key}
                  css={css`
                    grid-column: span 12 / span 12;
                    position: relative;
                    margin-bottom: 1.25rem;

                    ${MEDIA_QUERIES?.desktop} {
                      grid-column: span 4 / span 4;
                      margin-bottom: 3rem;
                    }
                  `}
                >
                  <ProductCard
                    product={product}
                    variant={product?.variants?.[0]}
                  />
                </div>
              );
            })}

        {button && (
          <ButtonContainer>
            <Go to={button?.url?.url}>
              <Button
                _css={css`
                  padding: 0 3rem;
                `}
                border="var(--color-black)"
                text={button?.url?.text}
              />
            </Go>
          </ButtonContainer>
        )}
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment ProductGridFragment on SanityProductGrid {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    withFilters

    button {
      url {
        text
        url
      }
    }

    products {
      title
      handle
      archived

      description
      seoDescription
      seoKeywords

      category {
        title
      }

      primaryImage {
        asset {
          gatsbyImageData(
            width: 512
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }

      secondaryImage {
        asset {
          gatsbyImageData(
            width: 512
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }

      sourceData {
        id
        title
        handle
        vendor
        description
        tags

        images {
          edges {
            node {
              originalSrc
            }
          }
        }
      }

      variants {
        id
        title

        sourceData {
          availableForSale
          id
          sku
          title

          priceV2 {
            amount
            currencyCode
          }

          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
`;

export default ProductGrid;
